<template>
  <div class="optimizer">
    <v-form @submit.prevent="getData">
      <v-row class="pb-6 d-flex justify-center">
        <v-col cols="2" class="pt-2">
          <a-select
            v-model="$v.formInputPlanogram.$model"
            :items="planograms"
            solo
            v-on:input="changePlanogram"
            label="Layout"
            style="width: 200px"
          >
          </a-select>
        </v-col>

        <v-col cols="1" class="pt-5 ml-2">
          <v-hover v-slot="{ hover }">
            <v-btn
              type="submit"
              color="white--text"
              :style="{ 'background-color': hover ? '#31649B' : '#424242' }"
              class=""
              >Go !</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
    </v-form>
    <PlanogramAnalytics
      v-if="show"
      analytic="Otimization"
      :planogram="this.loadedDiagram"
      :season="this.formInputSeason"
    />
  </div>
</template>
<script>
import ASelect from "@/components/ASelect.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AppError from "@/utils/AppError.js";
import PlanogramAnalytics from "@/components/PlanogramAnalytics.vue";

export default {
  name: "Optimizer",

  components: {
    PlanogramAnalytics,
    ASelect,
  },

  mounted: function () {
    this.getStore();
    this.getPlanograms();
    this.getLoadedPlanogram();
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    formInputPlanogram: { required },
    formInputSeason: { required },
  },

  data() {
    return {
      show: false,
      planograms: null,

      // Form inputs
      formInputPlanogram: null,
      formInputSeason: null,

      loadedDiagram: null,
      store: null,
    };
  },

  computed: {
    seasons() {
      return ["Summer", "Winter", "Christmas"];
    },
  },

  methods: {
    /**
     * Reset all the fields.
     * @public
     */
    resetFields() {
      this.$v.$reset();
      this.show = false;
      this.formInputSeason = null;
    },
    /**
     * On planogram change.
     * @public
     */
    changePlanogram() {
      this.resetFields();

      /*let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.$store.dispatch("util/stopLoading");
        });*/
    },
    /**
     * Get all the planograms.
     * @public
     */
    getPlanograms() {
      this.planograms = ["Simulator 1", "Simulator 2", "Simulator 3"];
      this.formInputPlanogram = this.planograms[0];
    },
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedPlanogram() {
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get analytic data.
     * @public
     */
    getData() {
      if (this.formInputPlanogram == "Planogram") {
        this.$v.formInputSeason.$touch();
        if (this.$v.formInputSeason.$invalid) {
          return;
        }
      }
      this.show = true;
    },
  },
};
</script>
